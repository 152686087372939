import * as React from 'react';
import { useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { useQuery, gql } from '@apollo/client';
import { laptopSize, mobileSize } from '../../../util/variables';
import { FaAngleDown } from 'react-icons/fa';
import { CompanyPortfolioModal } from '../../Modal';
import { AppText } from '../../ui';
import { prismicClient, Prismic, RichText } from '../../../services';
import moment from 'moment';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

const orderedCategories = [
  'Finance',
  'Food',
  'Healthcare',
  'Retail',
  'Operations',
  'Logistics',
  'Construction',
];

export interface Company {
  id: number;
  name: string;
  logo_url: string;
  headquarters: string;
  founded_date: number;
  invested_date: number;
  about: string;
  website: string;
  bw_logo_url: string;
  primary_industry_string: string;
  primary_trend_string: string;
  primary_trend: {
    name: string;
  };
  summary_text: any;
  primary_industry: {
    name: string;
  };
  trend: Array<{ id: number; name: string }>;
  industry: Array<{ id: number; name: string }>;
}

const OurCompaniesSegment: React.FC = React.memo(() => {
  const [showModal, setShowModal] = useState(false);
  const [companyData, setCompanyData] = useState<Company>({});

  const [data, setData] = useState([] as any);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'companies'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        setData(response.results.map((item: any) => item.data));
        console.log('prismic data: ', data);
      }
    };

    fetchData();
  }, []);

  if (data.length < 1) {
    return (
      <OurCompaniesContainer>
        <h5>Loading...</h5>
      </OurCompaniesContainer>
    );
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleModal = (item: object) => {
    setShowModal(!showModal);
    setCompanyData(item);
  };

  const categories = data
    .map((item: any) => item.category)
    .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
    .sort((a: any, b: any) => orderedCategories.indexOf(a) - orderedCategories.indexOf(b));

  const useScrollTo = (id: string) => {
    useLayoutEffect(() => {
      if (id && typeof window !== 'undefined') {
        const el = document.getElementById(id);
        const top = window.scrollY + el.getBoundingClientRect().top - 120;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    }, [id]);
  };

  const MakeCompanyList = () => {
    const [focus, _] = useQueryParam('focus', StringParam);

    const focusIsInList = () => {
      return data
        .slice()
        .map((company: any) => RichText.asText(company.name).toLocaleLowerCase().replace(/\s/g, ''))
        .includes(focus?.toLocaleLowerCase());
    };

    if (!!focus && focusIsInList(focus)) {
      useScrollTo(focus.toLocaleLowerCase());
    }

    const companiesList = categories.map((item: any) => (
      <CompaniesContainerDiv key={item}>
        <SegmentTitle>{item}</SegmentTitle>
        <InvestedCompaniesList>
          {data
            .slice()
            .filter((dataPoint: any) => item === dataPoint.category)
            .sort((a: any, b: any) => a.order_number - b.order_number)
            .map((company: any, index: number) => {
              return (
                <InvestedCompanyBox
                  key={company.id}
                  id={RichText.asText(company.name).toLocaleLowerCase().replace(/\s/g, '')}
                  onClick={() => {
                    console.log(company);
                    handleModal({
                      id: `${index}-${RichText.asText(company.name)}`,
                      name: RichText.asText(company.name),
                      logo_url: company.logo_url.url,
                      headquarters: company.headquarters,
                      founded_date: moment(company.founded_date, 'YYYY-MM-DD').format('YYYY'),
                      invested_date: moment(company.invested_date, 'YYYY-MM-DD').format('YYYY'),
                      summary_text: company.summary_text,
                      about: RichText.asText(company.about),
                      website: company.website.url,
                      bw_logo_url: company.logo_url.url,
                      primary_industry_string: company.primary_industry,
                      primary_trend_string: company.primary_trend,
                      primary_trend: {
                        name: company.primary_trend,
                      },
                      primary_industry: {
                        name: company.primary_industry,
                      },
                      trend: [],
                      industry: [],
                    });
                  }}
                >
                  <CompanyImage src={company.logo_url.url} />
                </InvestedCompanyBox>
              );
            })}
        </InvestedCompaniesList>
      </CompaniesContainerDiv>
    ));

    if (!companiesList?.length) {
      return <NoResults>No results. Try a different combination of filters.</NoResults>;
    }

    return companiesList;
  };

  return (
    <>
      <CompanyPortfolioModal showModal={showModal} toggleModal={toggleModal} data={companyData} />
      <OurCompaniesContainer>
        <MakeCompanyList />
      </OurCompaniesContainer>
    </>
  );
});

const SegmentTitle = styled(AppText)`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
`;

const NoResults = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  text-align: center;
  color: ${theme.WHITE_COLOR};
  opacity: 0.8;
  font-size: 20px;
  margin: 50px;
  margin-bottom: 100px;
`;

const OurCompaniesContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  display: block;
  width: 100%;
  h5 {
    color: ${theme.NEW_BLACK_COLOR};
    font-size: 20px;
    text-align: center;
  }
`;

const CompaniesContainerDiv = styled.div`
  max-width: 1250px;
  margin: 0px auto;
  padding-left: 25px;
  padding-right: 25px;
`;

const InvestedCompaniesList = styled.div`
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  padding: 16px 0px 48px 0px;
`;

const CompanyImage = styled.img`
  max-height: 80%;
  max-width: 80%;
  @media ${laptopSize} {
    max-height: 80%;
    max-width: 80%;
  }
  @media ${mobileSize} {
  }
`;

const InvestedCompanyBox = styled.div`
  height: 240px;
  max-width: 240px;
  width: 20%;
  opacity: 1;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.WHITE_COLOR};
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  border: 1px solid ${theme.GREY_600};
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 10px;
  transition: 300ms all ease-in-out;
  filter: grayscale(1);
  :hover {
    cursor: pointer;
    filter: grayscale(0);
  }

  @media ${laptopSize} {
    width: 30vw;
    height: 30vw;
  }
  @media ${mobileSize} {
    width: 50%;
    height: 40vw;
    width: 40vw;
  }
`;

export { OurCompaniesSegment };
